import React from "react"
import { Helmet } from "react-helmet"

const CustomFonts = () => (
  <Helmet>
      <link
          rel="preload"
          href="/google-fonts.css"
      />
    <link
      rel="stylesheet"
      href="/google-fonts.css"
    />
  </Helmet>
)

export default CustomFonts
