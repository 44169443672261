import React from "react"
import {ProfileType} from "../../types"

const About = ({about, certifications, experience}) => (
    <>
        <h5 className="font-header font-semibold text-front text-sm uppercase mb-3">
            Professional experience highlights
        </h5>
        <div className="font-text text-sm pb-12 leading-normal whitespace-pre-line">
            {experience}
        </div>
        <h5 className="font-header font-semibold text-front text-sm uppercase mb-3">
            Certifications
        </h5>
        <div dangerouslySetInnerHTML={{ __html: certifications }} className="font-text text-sm pb-12 leading-normal whitespace-pre-line"></div>
        <h5 className="font-header font-semibold text-front text-sm uppercase mb-3">
            About
        </h5>
        <div className="font-text text-sm pb-12 leading-normal whitespace-pre-line">
            {about}
        </div>
    </>
)

About.propTypes = {
    about: ProfileType.about,
    certifications: ProfileType.certifications,
    experience: ProfileType.experience,
}

export default About
