import React from "react"
import { number, string } from "prop-types"
import SkillSvg from "./skill.svg"

const Skill = ({ skill, i }) => (
  <li
    className={`relative flex justify-center items-center`} style={{'borderColor': '#3f653c'}}
  >
    <span className="absolute font-header font-semibold text-front text-xl px-2 text-center">
      {skill}
    </span>
    <SkillSvg className={`w-full`} style={{'borderColor': '#3f653c', 'color': '#3f653c'}}/>
  </li>
)

Skill.propTypes = {
  skill: string.isRequired,
  i: number.isRequired,
}

export default Skill
